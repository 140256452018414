<template>
  <div class="container">
    <h1 class="mt-4">管理员注册</h1>
    <form @submit.prevent="adminRegister">
      <div class="mb-3">
        <label for="phone" class="form-label">手机号</label>
        <input type="text" class="form-control" id="phone" v-model="phone" required>
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">密码</label>
        <input type="password" class="form-control" id="password" v-model="password" required>
      </div>
      <button type="submit" class="btn btn-primary">注册</button>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      phone: '',
      password: ''
    };
  },
  methods: {
    ...mapActions(['register']),
    async adminRegister() {
      try {
        await this.register({ phone: this.phone, password: this.password, isAdmin: 1 });
        this.$router.push('/admin/login');
      } catch (error) {
        console.error('Registration failed:', error);
      }
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  background: linear-gradient(135deg, #1f4037 0%, #99f2c8 100%);
  font-family: 'Roboto', sans-serif;
  color: white;
}

.container {
  max-width: 500px;
  margin-top: 50px;
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s, box-shadow 0.3s;
}

.container:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}

h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color:black;
}

.form-label {
  font-size: 1.2rem;
  color: white;
}

.form-control {
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.form-control:focus {
  background-color: rgba(255, 255, 255, 1);
}

.btn-primary {
  background: linear-gradient(135deg, #0f2027, #203a43, #2c5364);
  border: none;
  transition: background 0.3s, transform 0.3s;
}

.btn-primary:hover {
  background: linear-gradient(135deg, #2c5364, #203a43, #0f2027);
  transform: scale(1.05);
}
</style>
