<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img src="../assets/logo.png" alt="Logo" class="navbar-logo">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" href="/">首页</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/huawei">商城</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/orders">我的订单</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/cart">购物车</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/profile">我的</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">更多产品</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- 新增图片展示区域 -->
    <div class="image-showcase">
      <img src="../assets/hb1.jpg" alt="Showcase Image" class="img-fluid">
    </div>
    <div class="image-showcase">
      <img src="../assets/hb3.jpg" alt="Showcase Image" class="img-fluid">
    </div>
    <div class="container mt-4">
      <div class="intro-text mt-4">
        <h2>华为商城</h2>
      </div>
      <div class="row mb-4">
        <div v-for="flower in flowers" :key="flower.id" class="col-md-3 col-sm-6">
          <div class="card mb-4 shadow-sm">
            <img :src="flower.image" class="card-img-top" :alt="flower.name">
            <div class="card-body text-center">
              <h5 class="card-title">{{ flower.name }}</h5>
              <p class="card-text">{{ formatPrice(flower.price) }} 元</p>
              <div class="btn-group">
                <button @click="handleAddToCart(flower)" class="btn btn-primary btn-sm">加入购物车</button>
                <button @click="handlePlaceOrder(flower)" class="btn btn-secondary btn-sm">立即下单</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 页脚全宽调整 -->
    <footer class="footer-fullwidth">
      <div class="container">
        <div class="footer-content">
          维修信息 环保信息 法律与安全 销售服务中心招募 服务中心招募
        </div>
        <div class="footer-content">
          ©华为科技有限公司 京公网安备11010802043948号 京B2-20233141 京ICP备2023017139号
        </div>
        <div class="footer-content logos">
          <img src="https://via.placeholder.com/50" alt="Logo 1">
          <img src="https://via.placeholder.com/50" alt="Logo 2">
          <img src="https://via.placeholder.com/50" alt="Logo 3">
          <img src="https://via.placeholder.com/50" alt="Logo 4">
        </div>
      </div>
    </footer>
  </div>
</template>



<script>
import { mapState, mapActions } from 'vuex';
import Swal from 'sweetalert2';

export default {
  computed: {
    ...mapState(['flowers'])
  },
  methods: {
    ...mapActions(['fetchFlowers', 'addToCart', 'placeOrder']),
    formatPrice(price) {
      return price.toFixed(2);
    },
    handleAddToCart(flower) {
      this.addToCart(flower.id);
      Swal.fire({
        icon: 'success',
        title: '加入购物车成功',
        text: `已将 ${flower.name} 添加到购物车`,
        timer: 1500,
        showConfirmButton: false
      });
    },
    handlePlaceOrder(flower) {
      this.placeOrder(flower.id);
      Swal.fire({
        icon: 'success',
        title: '下单成功',
        text: `已成功下单 ${flower.name}`,
        timer: 1500,
        showConfirmButton: false
      });
    }
  },
  created() {
    this.fetchFlowers();
  }
};
</script>

<style scoped>
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  font-family: 'Arial', sans-serif;
}

.navbar {
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
}

.navbar-logo {
  height: 40px;
  margin-right: 10px;
}

.navbar-nav .nav-link {
  font-size: 1em;
  color: #333;
  padding: 0 15px;
}

.image-showcase {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.image-showcase img {
  width: 100%;
  max-height: 300px;
  height: auto;
  object-fit: cover;
}

.card {
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 1.25rem;
}

.card-text {
  font-size: 1.1rem;
}

.card-img-top {
  height: 200px;
  object-fit: cover;
}

.btn-primary {
  background-color: #007bff;
}

.btn-secondary {
  background-color: #6c757d;
}

footer.footer-fullwidth {
  width: 100%;
  background: #333333;
  padding: 20px;
  color: #fff;
  text-align: center;
}

.footer-content {
  margin-bottom: 10px;
}

.logos img {
  margin: 0 5px;
  filter: invert(100%);
}

.intro-text {
  text-align: center;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 10px;
  margin-bottom: 20px;
}
</style>



