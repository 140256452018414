<template>
  <div class="admin-flowers">
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container">
        <a class="navbar-brand" href="#">elema</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" href="/admin/orders">订单管理</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/admin/huawei">商品管理</a>
            </li>
            <li class="nav-item">
              <span class="nav-link" @click="handleLogout">退出</span>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="container mt-4">
      <h1 class="mb-4">商品管理</h1>
      <div class="flower-form mb-4">
        <h3>增加商品</h3>
        <form @submit.prevent="handleAddFlower">
          <div class="mb-3">
            <input type="text" v-model="newFlower.name" placeholder="名称" class="form-control" />
          </div>
          <div class="mb-3">
            <input type="number" v-model="newFlower.price" placeholder="价格" class="form-control" />
          </div>
          <div class="mb-3">
            <input type="text" v-model="newFlower.image" placeholder="图片URL" class="form-control" />
          </div>
          <button type="submit" class="btn btn-primary">添加商品</button>
        </form>
      </div>
      <h3>所有商品</h3>
      <div class="flower-list">
        <div v-for="flower in allFlowers" :key="flower.id" class="flower-item mb-4 p-3 border rounded shadow-sm">
          <img :src="flower.image" :alt="flower.name" class="img-thumbnail mb-3" />
          <p class="font-weight-bold mb-0">{{ flower.name }} - ¥{{ flower.price }}</p>
          <button @click="editFlower(flower)" class="btn btn-warning btn-sm mt-2">编辑</button>
          <button @click="handleDeleteFlower(flower.id)" class="btn btn-danger btn-sm mt-2">删除</button>
        </div>
      </div>
      <div v-if="editing" class="edit-flower mt-4">
        <h3>编辑商品</h3>
        <form @submit.prevent="handleUpdateFlower">
          <div class="mb-3">
            <input type="text" v-model="editFlowerData.name" placeholder="名称" class="form-control" />
          </div>
          <div class="mb-3">
            <input type="number" v-model="editFlowerData.price" placeholder="价格" class="form-control" />
          </div>
          <div class="mb-3">
            <input type="text" v-model="editFlowerData.image" placeholder="图片URL" class="form-control" />
          </div>
          <button type="submit" class="btn btn-primary">保存</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      newFlower: {
        name: '',
        price: '',
        image: ''
      },
      editing: false,
      editFlowerData: {}
    };
  },
  computed: {
    ...mapState(['allFlowers'])
  },
  methods: {
    ...mapActions(['fetchAllFlowers', 'addFlower', 'updateFlower', 'deleteFlower', 'logout']),
    async handleAddFlower() {
      await this.addFlower({
        name: this.newFlower.name,
        price: this.newFlower.price,
        image: this.newFlower.image
      });
      this.newFlower.name = '';
      this.newFlower.price = '';
      this.newFlower.image = '';
      this.fetchAllFlowers();
    },
    editFlower(flower) {
      this.editing = true;
      this.editFlowerData = { ...flower };
    },
    async handleUpdateFlower() {
      await this.updateFlower(this.editFlowerData);
      this.editing = false;
      this.fetchAllFlowers();
    },
    async handleDeleteFlower(flowerId) {
      await this.deleteFlower(flowerId);
      this.fetchAllFlowers();
    },
    handleLogout() {
      this.logout();
      this.$router.push('/admin/login');
    }
  },
  created() {
    this.fetchAllFlowers();
  }
};
</script>

<style scoped>
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  background: linear-gradient(to right, #4b0082, #8a2be2); /* 深紫色渐变背景 */
}

.navbar {
  background: linear-gradient(to right, #4b0082, #8a2be2); /* 深紫色渐变背景 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar-brand {
  font-weight: bold;
  font-size: 1.5rem;
  color: white;
}

.nav-link {
  color: white;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #dda0dd;
}

.container {
  max-width: 800px;
  font-family: 'Arial', sans-serif;
  color: white;
}

.flower-form,
.edit-flower {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.flower-list {
  display: flex;
  flex-wrap: wrap;
}

.flower-item {
  background-color: rgba(255, 255, 255, 0.1);
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  width: 200px;
  text-align: center;
  color: white;
}

.flower-item:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.img-thumbnail {
  max-width: 100%;
  height: auto;
}

.font-weight-bold {
  font-weight: bold;
  font-size: 1rem;
}

.btn-warning {
  margin-right: 5px;
}

h1, h3 {
  font-family: 'Georgia', serif;
}

h1 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
</style>
