<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img src="../assets/logo.png" alt="Logo" class="navbar-logo">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" href="/">首页</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/huawei">商城</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/login">登录</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/register">注册</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">应用市场</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">华为云</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">更多产品</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- 图片轮播 -->
    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../assets/hb2.jpg" class="d-block w-100" alt="Banner 1">
        </div>
        <div class="carousel-item">
          <img src="../assets/hb2.jpg" class="d-block w-100" alt="Banner 2">
        </div>
        <div class="carousel-item">
          <img src="../assets/hb3.jpg" class="d-block w-100" alt="Banner 3">
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
    
    <!-- 其余内容保持不变 -->
    <!-- 页脚 -->
    <footer>
      <div class="footer-content">
        维修信息 环保信息 法律与安全 销售服务中心招募 服务中心招募
      </div>
      <div class="footer-content">
        ©华为科技有限公司 京公网安备11010802043948号 京B2-20233141 京ICP备2023017139号
      </div>
      <div class="footer-content logos">
        <img src="https://via.placeholder.com/50" alt="Logo 1">
        <img src="https://via.placeholder.com/50" alt="Logo 2">
        <img src="https://via.placeholder.com/50" alt="Logo 3">
        <img src="https://via.placeholder.com/50" alt="Logo 4">
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Home',
  mounted() {
    // 动画效果代码可以保留，如有需要
    this.animateBlocks();
  },
  methods: {
    animateBlocks() {
      const blocks = document.querySelectorAll('.block');
      blocks.forEach((block, index) => {
        setTimeout(() => {
          block.classList.add('active');
        }, index * 300);
      });
    }
  }
};
</script>
<style scoped>
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.navbar {
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
}

.navbar-logo {
  height: 40px;
  margin-right: 10px;
}

.navbar-nav .nav-link {
  font-size: 1em;
  color: #333;
  padding: 0 10px;
}

.hero {
  position: relative;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hero-banner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-text {
  z-index: 1000;
  position: absolute;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
}

.hero-text h1 {
  font-size: 3em;
  margin: 0;
}

.hero-text h2 {
  font-size: 2em;
  margin: 10px 0;
}

footer {
  background: #333;
  padding: 20px;
  text-align: center;
  color: #fff;
}

.footer-content {
  margin-bottom: 10px;
}

.logos img {
  margin: 0 5px;
  filter: invert(100%);
}
.hero {
  position: relative;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hero-banner {
  position: absolute; /* 确保图片作为背景 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0; /* 图片在最底层 */
}

.hero-text {
  z-index: 1; /* 确保文本位于图片上方 */
  position: relative;
  margin-left: 800px;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0); /* 半透明背景 */
  padding: 20px;
  border-radius: 10px;
  max-width: 80%; /* 限制文本宽度以防止超出屏幕 */
}

.hero-text h1 {
  font-size: 3em;
  margin: 0;
}

.hero-text h2 {
  font-size: 2em;
  margin: 10px 0;
}

footer {
  background: #333;
  padding: 20px;
  text-align: center;
  color: #fff;
}

.footer-content {
  margin-bottom: 10px;
}

.logos img {
  margin: 0 5px;
  filter: invert(100%);
}
/* 基本样式保持不变，如有需要添加或调整特定样式 */
.navbar {
  background: #fff; /* 背景颜色 */
  border-bottom: 1px solid #e5e5e5; /* 边框样式 */
}

</style>


