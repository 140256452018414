<template>
  <div class="login-container">
    <form @submit.prevent="submitAdminLogin" class="login-form">
      <h1>管理员登录</h1>
      <input v-model="phone" type="text" placeholder="手机号" required />
      <input v-model="password" type="password" placeholder="密码" required />
      <button type="submit">登录</button>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      phone: '',
      password: ''
    };
  },
  methods: {
    ...mapActions(['login']),
    async submitAdminLogin() {
      await this.login({ phone: this.phone, password: this.password });
      this.$router.push('/admin/huawei');
    }
  }
};
</script>

<style scoped>
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  background: linear-gradient(to right, #0f2027, #203a43, #2c5364); /* 深蓝色渐变背景 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.login-form {
  width: 300px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.2); /* 更高透明度 */
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  text-align: center;
}

h1 {
  font-family: 'Georgia', serif;
  font-size: 2rem;
  margin-bottom: 20px;
  color: black; /* 白色文字 */
}

input[type="text"], input[type="password"], button {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type="text"], input[type="password"] {
  background: rgba(255, 255, 255, 0.9); /* 输入框背景颜色 */
  color: #000; /* 黑色文字 */
}

input[type="text"]::placeholder, input[type="password"]::placeholder {
  color: #999; /* 输入框占位符颜色 */
}

button {
  background-color: #007BFF; /* 按钮背景色 */
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3; /* 按钮悬停背景色 */
}
</style>
