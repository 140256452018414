<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img src="../assets/logo.png" alt="Logo" class="navbar-logo"> 商城
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" href="/">首页</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/huawei">商城</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/orders">我的订单</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/cart">购物车</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/profile">我的</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">更多产品</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="container mt-4 text-center">
      <h1>个人信息</h1>
      <div class="mb-4">
        <img :src="user.avatar" class="avatar" alt="头像">
      </div>
      <div class="mb-3">
        <strong>年龄：</strong>{{ user.age }}
      </div>
      <div class="mb-3">
        <strong>手机号：</strong>{{ user.phone }}
      </div>
      <button class="btn btn-primary" @click="showModal">修改信息</button>

      <!-- 修改信息模态框 -->
      <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="editModalLabel">修改信息</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="updateProfile">
                <div class="mb-3">
                  <label for="age" class="form-label">年龄</label>
                  <input type="number" class="form-control" id="age" v-model="age" required>
                </div>
                <div class="mb-3">
                  <label for="phone" class="form-label">手机号</label>
                  <input type="text" class="form-control" id="phone" v-model="phone" required>
                </div>
                <div class="mb-3">
                  <label for="avatar" class="form-label">头像URL</label>
                  <input type="text" class="form-control" id="avatar" v-model="avatar" required>
                </div>
                <button type="submit" class="btn btn-primary">更新信息</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      age: this.$store.state.user?.age || '',
      phone: this.$store.state.user?.phone || '',
      avatar: this.$store.state.user?.avatar || ''
    };
  },
  computed: {
    ...mapState(['user'])
  },
  watch: {
    user(newUser) {
      if (newUser) {
        this.age = newUser.age;
        this.phone = newUser.phone;
        this.avatar = newUser.avatar;
      }
    }
  },
  methods: {
    ...mapActions(['updateUser']),
    async updateProfile() {
      await this.updateUser({ age: this.age, phone: this.phone, avatar: this.avatar });
      Swal.fire({
        icon: 'success',
        title: '信息更新成功',
        showConfirmButton: false,
        timer: 1500
      });
      const modal = bootstrap.Modal.getInstance(document.getElementById('editModal'));
      modal.hide();
    },
    showModal() {
      const modal = new bootstrap.Modal(document.getElementById('editModal'));
      modal.show();
    }
  },
  created() {
    if (!this.user) {
      this.$store.dispatch('fetchUser');
    }
  }
};
</script>

<style scoped>
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.navbar {
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
}

.navbar-logo {
  height: 40px;
  margin-right: 10px;
}

.navbar-nav .nav-link {
  font-size: 1em;
  color: #333;
  padding: 0 10px;
  white-space: nowrap;
}

.container {
  max-width: 600px;
  margin: 0 auto;
}

.avatar {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin: 0 auto;
  display: block;
  border-radius: 0; /* 方块头像 */
}

.modal-backdrop.show {
  opacity: 0.8;
}

h1, h5, p, strong, label, button {
  color: #02b6fd; /* 所有字体变成蓝色 */
}

.btn-primary {
  background-color: #FFD700; /* 改为黄色 */
  border: none;
  color: white;
  font-size: 0.9rem;
  transition: background-color 0.3s;
  border-radius: 20px; /* 圆角 */
}

.btn-primary:hover {
  background-color: #FFC107; /* 较深的黄色 */
}

footer {
  background: #333;
  padding: 20px;
  text-align: center;
  color: #fff;
}

.footer-content {
  margin-bottom: 10px;
}

.logos img {
  margin: 0 5px;
  filter: invert(100%);
}
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* 主内容区域使用flex布局 */
body {
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1; /* 容器占据所有可用空间 */
}

footer {
  background: #333;
  color: #fff;
  text-align: center;
  padding: 20px;
  margin-top: auto; /* 将footer推到最下方 */
}

</style>
