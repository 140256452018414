import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    user: null,
    token: localStorage.getItem('token') || null,
    flowers: [],
    orders: [],
    cart: [],
    allOrders: [],
    allFlowers: [],
    users: []
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
      if (token) {
        localStorage.setItem('token', token);
      } else {
        localStorage.removeItem('token');
      }
    },
    setFlowers(state, flowers) {
      state.flowers = flowers;
    },
    setOrders(state, orders) {
      state.orders = orders;
    },
    setCart(state, cart) {
      state.cart = cart;
    },
    setAllOrders(state, orders) {
      state.allOrders = orders;
    },
    setAllFlowers(state, flowers) {
      state.allFlowers = flowers;
    },
    setUsers(state, users) {
      state.users = users;
    }
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await axios.post('http://localhost:5000/api/users/login', credentials);
        const token = response.data.token;
        commit('setToken', token);
        const userResponse = await axios.get('http://localhost:5000/api/users/me', {
          headers: { Authorization: `Bearer ${token}` }
        });
        commit('setUser', userResponse.data);
        // callback(response)
      } catch (error) {
        console.error('Login failed:', error);
        // callback(error)
      }
    },
    async register({ dispatch }, user) {
      try {
        await axios.post('http://localhost:5000/api/users/register', user);
        dispatch('login', { phone: user.phone, password: user.password });
      } catch (error) {
        console.error('Register failed:', error);
      }
    },
    async fetchUser({ commit, state }) {
      try {
        const response = await axios.get('http://localhost:5000/api/users/me', {
          headers: { Authorization: `Bearer ${state.token}` }
        });
        commit('setUser', response.data);
      } catch (error) {
        console.error('Fetch user failed:', error);
      }
    },
    async updateUser({ dispatch, state }, userData) {
      try {
        await axios.put('http://localhost:5000/api/users/me', userData, {
          headers: { Authorization: `Bearer ${state.token}` }
        });
        dispatch('fetchUser');
      } catch (error) {
        console.error('Update user failed:', error);
      }
    },
    async fetchFlowers({ commit }) {
      try {
        const response = await axios.get('http://localhost:5000/api/flowers');
        commit('setFlowers', response.data);
      } catch (error) {
        console.error('Fetch flowers failed:', error);
      }
    },
    async fetchOrders({ commit, state }) {
      try {
        const response = await axios.get('http://localhost:5000/api/orders/my', {
          headers: { Authorization: `Bearer ${state.token}` }
        });
        commit('setOrders', response.data);
      } catch (error) {
        console.error('Fetch orders failed:', error);
      }
    },
    async placeOrder({ dispatch, state }, flowerId) {
      try {
        await axios.post('http://localhost:5000/api/orders', { flowerId }, {
          headers: { Authorization: `Bearer ${state.token}` }
        });
        dispatch('fetchOrders');
      } catch (error) {
        console.error('Place order failed:', error);
      }
    },
    async cancelOrder({ dispatch, state }, orderId) {
      try {
        await axios.put(`http://localhost:5000/api/orders/${orderId}/cancel`, {}, {
          headers: { Authorization: `Bearer ${state.token}` }
        });
        dispatch('fetchOrders');
      } catch (error) {
        console.error('Cancel order failed:', error);
      }
    },
    async fetchCart({ commit, state }) {
      try {
        const response = await axios.get('http://localhost:5000/api/cart', {
          headers: { Authorization: `Bearer ${state.token}` }
        });
        commit('setCart', response.data);
      } catch (error) {
        console.error('Fetch cart failed:', error);
      }
    },
    async addToCart({ dispatch, state }, flowerId) {
      try {
        await axios.post('http://localhost:5000/api/cart', { flowerId }, {
          headers: { Authorization: `Bearer ${state.token}` }
        });
        dispatch('fetchCart');
      } catch (error) {
        console.error('Add to cart failed:', error);
      }
    },
    async removeFromCart({ dispatch, state }, flowerId) {
      try {
        await axios.delete(`http://localhost:5000/api/cart/${flowerId}`, {
          headers: { Authorization: `Bearer ${state.token}` }
        });
        dispatch('fetchCart');
      } catch (error) {
        console.error('Remove from cart failed:', error);
      }
    },
    async fetchAllOrders({ commit, state }) {
      try {
        const response = await axios.get('http://localhost:5000/api/orders', {
          headers: { Authorization: `Bearer ${state.token}` }
        });
        commit('setAllOrders', response.data);
      } catch (error) {
        console.error('Fetch all orders failed:', error);
      }
    },
    async confirmOrder({ dispatch, state }, orderId) {
      try {
        await axios.put(`http://localhost:5000/api/orders/${orderId}/confirm`, {}, {
          headers: { Authorization: `Bearer ${state.token}` }
        });
        dispatch('fetchAllOrders');
      } catch (error) {
        console.error('Confirm order failed:', error);
      }
    },
    async deleteOrder({ dispatch, state }, orderId) {
      try {
        await axios.delete(`http://localhost:5000/api/orders/${orderId}`, {
          headers: { Authorization: `Bearer ${state.token}` }
        });
        dispatch('fetchAllOrders');
      } catch (error) {
        console.error('Delete order failed:', error);
      }
    },
    async fetchAllFlowers({ commit, state }) {
      try {
        const response = await axios.get('http://localhost:5000/api/flowers', {
          headers: { Authorization: `Bearer ${state.token}` }
        });
        commit('setAllFlowers', response.data);
      } catch (error) {
        console.error('Fetch all flowers failed:', error);
      }
    },
    async addFlower({ dispatch, state }, flower) {
      try {
        await axios.post('http://localhost:5000/api/flowers', flower, {
          headers: { Authorization: `Bearer ${state.token}` }
        });
        dispatch('fetchAllFlowers');
      } catch (error) {
        console.error('Add flower failed:', error);
      }
    },
    async updateFlower({ dispatch, state }, flower) {
      try {
        await axios.put(`http://localhost:5000/api/flowers/${flower.id}`, flower, {
          headers: { Authorization: `Bearer ${state.token}` }
        });
        dispatch('fetchAllFlowers');
      } catch (error) {
        console.error('Update flower failed:', error);
      }
    },
    async deleteFlower({ dispatch, state }, flowerId) {
      try {
        await axios.delete(`http://localhost:5000/api/flowers/${flowerId}`, {
          headers: { Authorization: `Bearer ${state.token}` }
        });
        dispatch('fetchAllFlowers');
      } catch (error) {
        console.error('Delete flower failed:', error);
      }
    },
    async fetchUsers({ commit, state }) {
      try {
        const response = await axios.get('http://localhost:5000/api/users', {
          headers: { Authorization: `Bearer ${state.token}` }
        });
        commit('setUsers', response.data);
      } catch (error) {
        console.error('Fetch users failed:', error);
      }
    },
    logout({ commit }) {
      commit('setToken', null);
      commit('setUser', null);
    }
  },
  modules: {}
});
