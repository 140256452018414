<template>
  <div class="register-container">
    <div class="form-container">
      <h1>注册</h1>
      <form @submit.prevent="handleRegister">
        <input
          v-model="phone"
          type="text"
          placeholder="手机号"
          required
          @input="validatePhone"
        />
        <span v-if="phoneError" class="error-message">{{ phoneError }}</span>
        <input
          v-model="password"
          type="password"
          placeholder="密码"
          required
          @input="validatePassword"
        />
        <span v-if="passwordError" class="error-message">{{ passwordError }}</span>
        <input
          v-model="age"
          type="number"
          placeholder="年龄"
        />
        <input
          v-model="avatar"
          type="text"
          placeholder="头像URL"
        />
        <button type="submit" :disabled="isSubmitDisabled">注册</button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      phone: '',
      password: '',
      age: '',
      avatar: '',
      phoneError: '',
      passwordError: ''
    };
  },
  computed: {
    isSubmitDisabled() {
      return this.phoneError || this.passwordError || !this.phone || !this.password;
    }
  },
  methods: {
    ...mapActions(['register']),
    validatePhone() {
      const phonePattern = /^1[3-9]\d{9}$/;
      if (!phonePattern.test(this.phone)) {
        this.phoneError = '请输入有效的中国大陆手机号';
      } else {
        this.phoneError = '';
      }
    },
    validatePassword() {
      if (this.password.length < 6) {
        this.passwordError = '密码必须六位以上';
      } else {
        this.passwordError = '';
      }
    },
    async handleRegister() {
      if (!this.isSubmitDisabled) {
        try {
          await this.register({ phone: this.phone, password: this.password, age: this.age, avatar: this.avatar });
          Swal.fire({
            icon: 'success',
            title: '注册成功',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              popup: 'small-swal-popup'
            }
          }).then(() => {
            this.$router.push('/login'); // 注册成功后跳转到登录页面
          });
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: '注册失败',
            text: error.response?.data?.message || '请检查您的信息',
            customClass: {
              popup: 'small-swal-popup'
            }
          });
        }
      }
    }
  }
};
</script>

<style scoped>
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Arial', sans-serif;
}

.register-container {
  background: linear-gradient(135deg, #2c3e50, #2980b9);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: gradientAnimation 15s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.form-container {
  width: 300px;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h1 {
  font-family: 'Georgia', serif;
  font-size: 2rem;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

input {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #2980b9;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:hover:enabled {
  background-color: #1a73e8;
}

.error-message {
  color: red;
  font-size: 0.9rem;
}

/* Custom Swal2 styles */
.small-swal-popup {
  width: 300px !important;
  font-size: 14px !important;
}
</style>
