<template>
    <div>
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container">
          <a class="navbar-brand" href="#">管理后台</a>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link" href="/admin/orders">订单管理</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/admin/huawei">商品管理</a>
              </li>
              <li class="nav-item">
                <span class="nav-link" @click="handleLogout">退出</span>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="container mt-4">
        <h1>用户管理</h1>
        <div class="order-header d-flex justify-content-between align-items-center p-3 mb-3 border-bottom">
          <h5 class="m-0">用户</h5>
          <h5 class="m-0">操作</h5>
        </div>
        <div v-for="user in users" :key="user.id" class="order-item mb-4 p-3 border rounded shadow-sm">
          <div class="d-flex justify-content-between align-items-center">
            <p class="font-weight-bold mb-0">{{ user.phone }} - {{ user.age }} - {{ user.avatar }}</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  
  export default {
    computed: {
      ...mapState(['users'])
    },
    methods: {
      ...mapActions(['fetchUsers']),
    },
    created() {
      this.fetchUsers();
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 800px;
  }
  
  .order-header {
    background-color: #f1f1f1;
    font-weight: bold;
    font-size: 1.2rem;
  }
  
  .order-item {
    background-color: #fff;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .order-item:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .font-weight-bold {
    font-weight: bold;
    font-size: 1rem;
  }
  
  h1, h5 {
    font-family: 'Georgia', serif;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  
  h5 {
    font-size: 1.2rem;
  }
  .navbar {
    background: linear-gradient(135deg, #0f2027, #203a43, #4b0560);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .navbar-brand {
    font-weight: bold;
    font-size: 1.5rem;
  }
  
  .nav-link {
    color: white;
    transition: color 0.3s;
  }
  .container {
    max-width: 800px;
  }
  
  .navbar {
    background: linear-gradient(135deg, #0f2027, #203a43, #2c5364);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .navbar-brand {
    font-weight: bold;
    font-size: 1.5rem;
  }
  
  .nav-link {
    color: white;
    transition: color 0.3s;
  }
  
  .nav-link:hover {
    color: #99f2c8;
  }
  
  .order-header {
    background-color: rgba(0, 0, 0, 0.5);
    font-weight: bold;
    font-size: 1.2rem;
    border-radius: 5px;
    color: white;
  }
  
  .order-item {
    background-color: rgba(255, 255, 255, 0.1);
    transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
    color: black;
  }
  
  .order-item:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.2);
  }
  
  .font-weight-bold {
    font-weight: bold;
    font-size: 1rem;
  }
  
  h1, h5 {
    font-family: 'Roboto', sans-serif;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
  
  h5 {
    font-size: 1.2rem;
  }
  
  </style>
  