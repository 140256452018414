<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img src="../assets/logo.png" alt="Logo" class="navbar-logo">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" href="/">首页</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/huawei">商城</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/orders">我的订单</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/cart">购物车</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/profile">我的</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">更多产品</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="container mt-4">
      <h1>购物车</h1>
      <div class="order-header d-flex justify-content-between align-items-center p-3 mb-3 border-bottom">
        <h5 class="m-0">商品信息</h5>
        <h5 class="m-0">操作</h5>
      </div>
      <div v-if="cart.length === 0" class="empty-cart text-center mt-5">
        <p>这里什么都没有哦！</p>
        <a href="/flowers" class="btn btn-primary">去逛逛</a>
      </div>
      <div v-else>
        <div v-for="item in cart" :key="item.id" class="order-item mb-4 p-3 border rounded shadow-sm">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h5 class="font-weight-bold mb-1">{{ item.Flower ? item.Flower.name : 'HuaWei' }}</h5>
              <p class="mb-1">HuaWeiid: {{ item.flowerId ? item.flowerId : '未知ID' }}</p>
              <p class="mb-1">下单时间: {{ formatDate(item.createdAt) }}</p>
            </div>
            <button @click="removeFromCart(item.id)" class="btn btn-danger btn-sm">移出购物车</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState(['cart'])
  },
  methods: {
    ...mapActions(['fetchCart', 'removeFromCart']),
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString('zh-CN', options);
    },
    formatPrice(price) {
      return price.toFixed(2);
    }
  },
  created() {
    this.fetchCart();
  }
};
</script>

<style scoped>
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.navbar {
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
}

.navbar-logo {
  height: 40px;
  margin-right: 10px;
}

.navbar-nav .nav-link {
  font-size: 1em;
  color: #333;
  padding: 0 10px;
  white-space: nowrap; /* 保证文字在一行显示 */
}

.container {
  max-width: 800px;
}

.order-header {
  background-color: #f1f1f1;
  font-weight: bold;
  font-size: 1.2rem;
}

.order-item {
  background-color: #fff;
  transition: transform 0.3s, box-shadow 0.3s;
}

.order-item:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.font-weight-bold {
  font-weight: bold;
  font-size: 1.2rem;
}

.btn-danger {
  background-color: #dc3545;
  border: none;
  color: white;
  font-size: 0.9rem;
  transition: background-color 0.3s;
}

.btn-danger:hover {
  background-color: #c82333;
}

h1, h5 {
  font-family: 'Georgia', serif;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

h5 {
  font-size: 1.5rem;
}

.empty-cart {
  font-family: 'Arial', sans-serif;
}

.empty-cart p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.empty-cart .btn {
  font-size: 1rem;
  padding: 0.5rem 1.5rem;
  background-color: #17a2b8;
}

footer {
  background: #333;
  padding: 20px;
  text-align: center;
  color: #fff;
}

.footer-content {
  margin-bottom: 10px;
}

.logos img {
  margin: 0 5px;
  filter: invert(100%);
}
</style>
