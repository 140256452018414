<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img src="../assets/logo.png" alt="Logo" class="navbar-logo">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" href="/">首页</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/huawei">商城</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/orders">我的订单</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/cart">购物车</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/profile">我的</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">更多产品</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="hero">
      <img src="../assets/banner4.jpg" alt="Banner Image" class="hero-banner">
    </div>
    <div class="container mt-4">
      <h1>我的订单</h1>
      <div class="order-header d-flex justify-content-between align-items-center p-3 mb-3 border-bottom">
        <h5 class="m-0">订单信息</h5>
        <h5 class="m-0">状态</h5>
      </div>
      <div v-if="orders.length === 0" class="empty-orders text-center mt-5">
        <p>目前没有订单！</p>
        <a href="/flowers" class="btn btn-primary">去逛逛</a>
      </div>
      <div v-else class="orders-container d-flex">
        <img src="../assets/banner5.webp" alt="Order Banner" class="order-banner mr-4">
        <div class="orders-list">
          <div v-for="order in orders" :key="order.id" class="order-item mb-4 p-3 border rounded shadow-sm">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center order-info">
                <img :src="order.Flower.image" class="order-image mr-3" alt="Flower Image">
                <div class="ml-3">
                  <span class="font-weight-bold">{{ order.Flower.name }}</span>
                  <span class="ml-3">价格: {{ formatPrice(order.Flower.price) }} 元</span>
                  <span class="ml-3">时间: {{ formatDate(order.createdAt) }}</span>
                </div>
              </div>
              <div class="text-right">
                <p class="mb-1 font-weight-bold">{{ translateStatus(order.status) }}</p>
                <button @click="cancel(order.id)" v-if="order.status === 'pending'" class="btn btn-primary btn-sm">取消订单</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="footer-content">
        维修信息 环保信息 法律与安全 销售服务中心招募 服务中心招募
      </div>
      <div class="footer-content">
        ©华为科技有限公司 京公网安备11010802043948号 京B2-20233141 京ICP备2023017139号
      </div>
      <div class="footer-content logos">
        <img src="https://via.placeholder.com/50" alt="Logo 1">
        <img src="https://via.placeholder.com/50" alt="Logo 2">
        <img src="https://via.placeholder.com/50" alt="Logo 3">
        <img src="https://via.placeholder.com/50" alt="Logo 4">
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState(['orders'])
  },
  methods: {
    ...mapActions(['fetchOrders', 'cancelOrder']),
    cancel(orderId) {
      this.cancelOrder(orderId);
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString('zh-CN', options);
    },
    formatPrice(price) {
      return price.toFixed(2);
    },
    translateStatus(status) {
      const statusMap = {
        'pending': '待处理',
        'completed': '已完成',
        'canceled': '已取消'
      };
      return statusMap[status] || '未知状态';
    }
  },
  created() {
    this.fetchOrders();
  }
};
</script>

<style scoped>
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.navbar {
  background: #fff;
  border-bottom: 1px solid #e5e5e5;
}

.navbar-logo {
  height: 40px;
  margin-right: 10px;
}

.navbar-nav .nav-link {
  font-size: 1em;
  color: #333;
  padding: 0 10px;
}

.hero {
  position: relative;
  height: 50vh; /* 减小高度 */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hero-banner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn-primary {
  background-color: #FFD700; /* 改为黄色 */
  border: none;
  color: white;
  font-size: 0.9rem;
  transition: background-color 0.3s;
  border-radius: 20px; /* 圆角 */
}

.btn-primary:hover {
  background-color: #FFC107; /* 较深的黄色 */
}

.order-header {
  background-color: #f1f1f1;
  font-weight: bold;
  font-size: 1.2rem;
}

.orders-container {
  display: flex;
}

.order-banner {
  width: 200px;
  height: auto;
  object-fit: contain; /* 固定宽度，不拉伸 */
}

.orders-list {
  flex: 1;
}

.order-item {
  background-color: #fff;
  transition: transform 0.3s, box-shadow 0.3s;
}

.order-item:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.order-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
}

.font-weight-bold {
  font-weight: bold;
  font-size: 1.2rem;
}

.empty-orders {
  font-family: 'Arial', sans-serif;
}

.empty-orders p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.empty-orders .btn {
  font-size: 1rem;
  padding: 0.5rem 1.5rem;
}

.text-right {
  text-align: right;
}

.order-info {
  display: flex;
  align-items: center;
}

.order-info > div {
  display: flex;
  align-items: center;
}

footer {
  background: #333;
  padding: 20px;
  text-align: center;
  color: #fff;
}

.footer-content {
  margin-bottom: 10px;
}

.logos img {
  margin: 0 5px;
  filter: invert(100%);
}
.order-banner{
  width: 300px;
  height: 300px;
}
</style>
