<template>
  <div class="login-container">
    <div class="form-container">
      <h1>登录</h1>
      <form @submit.prevent="handleLogin">
        <input
          v-model="phone"
          type="text"
          placeholder="手机号"
          required
          @input="validatePhone"
        />
        <span v-if="phoneError" class="error-message">{{ phoneError }}</span>
        <input
          v-model="password"
          type="password"
          placeholder="密码"
          required
          @input="validatePassword"
        />
        <span v-if="passwordError" class="error-message">{{ passwordError }}</span>
        <button type="submit" :disabled="isSubmitDisabled">登录</button>
        <button @click="handleRegister" type="button" class="register-button">注册</button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      phone: '',
      password: '',
      phoneError: '',
      passwordError: ''
    };
  },
  computed: {
    isSubmitDisabled() {
      return this.phoneError || this.passwordError || !this.phone || !this.password;
    }
  },
  methods: {
    ...mapActions(['login']),
    validatePhone() {
      const phonePattern = /^1[3-9]\d{9}$/;
      if (!phonePattern.test(this.phone)) {
        this.phoneError = '请输入有效的中国大陆手机号';
      } else {
        this.phoneError = '';
      }
    },
    validatePassword() {
      if (this.password.length < 6) {
        this.passwordError = '密码必须六位以上';
      } else {
        this.passwordError = '';
      }
    },
    async handleLogin() {
      if (!this.isSubmitDisabled) {
        try {
          await this.submitLogin();
          Swal.fire({
            icon: 'success',
            title: '登录成功',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              popup: 'small-swal-popup'
            }
          });
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: '登录失败',
            text: '请检查您的手机号和密码',
            customClass: {
              popup: 'small-swal-popup'
            }
          });
        }
      }
    },
    async submitLogin() {
      await this.login({ phone: this.phone, password: this.password });
      this.$router.push('/huawei');
    },
    handleRegister() {
      this.$router.push('/register');
    }
  }
};
</script>

<style scoped>
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Arial', sans-serif;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(-45deg, #ff9a9e, #fad0c4, #fad0c4, #ff9a9e);
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.form-container {
  background: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: enterAnimation 0.5s ease-out forwards;
  transform: translateY(-20px);
  opacity: 0;
}

@keyframes enterAnimation {
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

h1 {
  font-family: 'Georgia', serif;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  width: 80%;
}

button {
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: #02b6fd;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  width: 80%;
}

button:disabled {
  background-color: #b2dffc;
  cursor: not-allowed;
}

button:hover:enabled {
  background-color: #02a1d8;
  transform: scale(1.05);
}

.error-message {
  color: red;
  font-size: 0.9rem;
}

.register-button {
  background-color: #f1f1f1;
  color: #666;
  border: 1px solid #ccc;
  margin-top: 10px;
}

.register-button:hover {
  background-color: #e2e2e2;
}
</style>
