import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import Flowers from '../views/HuaWei.vue';
import Cart from '../views/Cart.vue';
import Orders from '../views/Orders.vue';
import Profile from '../views/Profile.vue';
import AdminLogin from '../views/AdminLogin.vue';
import AdminRegister from '../views/AdminRegister.vue';
import AdminFlowers from '../views/AdminHuaWei.vue';
import AdminOrders from '../views/AdminOrders.vue';
import AdminUsers from '../views/AdminUsers.vue';

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/login', name: 'Login', component: Login },
  { path: '/register', name: 'Register', component: Register },
  { path: '/huawei', name: 'Flowers', component: Flowers },
  { path: '/cart', name: 'Cart', component: Cart },
  { path: '/orders', name: 'Orders', component: Orders },
  { path: '/profile', name: 'Profile', component: Profile },
  { path: '/admin/login', name: 'AdminLogin', component: AdminLogin },
  { path: '/admin/register', name: 'AdminRegister', component: AdminRegister },
  { path: '/admin/huawei', name: 'AdminFlowers', component: AdminFlowers },
  { path: '/admin/orders', name: 'AdminOrders', component: AdminOrders },
  { path: '/admin/users', name: 'AdminUsers', component: AdminUsers },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
